const RIPPage = () => {
  return (
    <>
    

      <section className="section is-small">
        <div className="container">
          <div className="is-threequarters">
            <h1 className="title has-text-midnightBlue is-spaced is-size-4-mobile">
              C19 Testing stopped selling coronavirus testing services in November 2022 and stopped processing coronavirus tests in May 2023.
            </h1>

                <h2 className="subtitle has-text-midnightBlue is-spaced">
              We're grateful to all our customers and even more grateful that the coronavirus pandemic is over.
            </h2>

          </div>
        </div>
      </section>
    </>
  )
}

export default RIPPage
